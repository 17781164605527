/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦联合创始人首页
 * @FilePath: /frontend/src/pages/rebate/shilishou/co-founder/rebate-home/index.js
 */
import CompRebateLayoutOne from "@/components/rebate/comp-rebate-layout-one/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getCoFounderRebateSum } from "@/service/rebate/shilishouRebate";
import RebateTable from "@/components/rebate/rebate-table/index.vue";

export default {
  name: "co-founder-rebate-home",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutOne,
    RebateTable,
  },
  data() {
    return {
      isRebateHome: true,
    };
  },
  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getCoFounderRebateSum({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};
